import React from 'react';
import { FluidObject } from 'gatsby-image';

import { faq_about, faq_how_buy, faq_membership } from './text_data';

import ScreenBox from '../shared/screen-box/ScreenBox';
import ButtonsBar from '../shared/buttons-bar/ButtonsBar';
import Background from '../shared/background/Background';
import FaqSection from './FaqSection';

import styles from './Faq.module.scss';

interface IFaqProps {
  background: FluidObject;
}

const Faq = ({ background }: IFaqProps) => (
  <>
    <ScreenBox
      background={background}
      text="Frequently<br /> Asked<br /> Questions"
      triangleColor="#3308c2"
    >
      <ButtonsBar showText={false} />
    </ScreenBox>
    <section className={styles.faqBody}>
      <Background color="rgba(183, 64, 255, 0.05)" />
      <div style={{ position: 'relative' }}>
        <FaqSection title="about CLICK" data={faq_about} />
        <FaqSection title="how to buy" data={faq_how_buy} />
        <FaqSection title="click membership" data={faq_membership} />
      </div>
    </section>
  </>
);

export default Faq;
