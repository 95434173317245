import React from 'react';

import styles from './Background.module.scss';

interface IBackgroundProps {
  color: string;
}

const Background = ({ color }: IBackgroundProps) => (
  <div className={styles.background} style={{ backgroundColor: color }} />
);

export default Background;
