import React from 'react';
import { FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import styles from './ScreenBox.module.scss';

interface IScreenBoxProps {
  background: FluidObject;
  text: string;
  triangleColor: string;
  children?: any;
}

const ScreenBox = ({ background, text, triangleColor, children }: IScreenBoxProps) => (
  <section className={styles.screenBox}>
    <div className={styles.triangle} style={{ background: triangleColor }}></div>
    <BackgroundImage
      Tag="div"
      fadeIn={false}
      className={styles.imageBackground}
      fluid={background}
    ></BackgroundImage>
    <div className={styles.textWrapper}>
      <h1 dangerouslySetInnerHTML={{ __html: text }} />
    </div>
    <div className={styles.whiteLine}></div>
    {children}
  </section>
);

export default ScreenBox;
