export const faq_about = [
  {
    title: `What is CLICK?`,
    description: `CLICK is the cannabis mouth spray for people who want the best that cannabis has to offer. It’s fast-acting, tastes great, fits in the palm of your hand and was specifically designed to complement your lifestyle. Whether you’re gearing up for a busy day with GO, recovering after a workout with RESTORE, taking a moment to yourself with CHILL, or ready for a great night’s sleep with DREAM, CLICK’s got you covered.`,
  },
  {
    title: 'What’s in CLICK?',
    description: `Very little, to be blunt. Each CLICK has a unique formulation of the highest grade THC and CBD, enhanced by all-natural supplements, such as green tea, boswellia, L-theanine, and melatonin and flavors like peppermint, pineapple, lemon, mango, and passionfruit. That’s it. 
    <br /><br/>
    Our sprays are <b>free</b> from pesticides, GMO, BSE (Bovine Spongiform encephalopathy Disease) and TSE (Transmissible spongiform encephalopathy), preservatives, gluten, peanuts, soybeans, milk, fish, crustacea, tree nuts, sulphites, buck wheat, wheat, yeast, egg, sugar alcohols, artificial flavours, colors or sweeteners and any banned substances. 
    <br /><br/>
    And they’re cruelty-free too; no animal products or products containing ingredients of animal origin are used in the production, equipment or packaging of our material. 
    <br /><br/>
    I guess you can say we put exactly what you want in a cannabis spray, and nothing you don’t!
    `,
  },
  {
    title: `How to use CLICK?`,
    description: `Hold upright, align the notches and spray under your tongue. For quicker onset and maximum absorption, hold contents under your tongue for 15 seconds.`,
  },
  {
    title: 'What are the THC:CBD ratios of CLICK sprays?',
    description: `Each spray– GO, RESTORE, CHILL and DREAM– has a different ratio in order to cause desired effects. GO is 1:0 THC:CBD, RESTORE is 1:5 THC CBD, CHILL is 2:1 THC:CBD, and DREAM is 1:2 THC:CBD. 
    `,
  },
  {
    title: 'Is CLICK an Indica, Sativa or Hybrid?',
    description: `None of the above! 
    <br /><br/>
    What makes those strains specific is the range of cannabinoids and terpenes they contain. We use a unique process called Nanoemulsion, that puts our sprays in their own category. We pull the cannabinoids out, concentrate them, and suspend them in water. Then, we add our favorite flavors and supplements that we know you’ll dig. 
    <br /><br/>
    The proportions of CBD to THC is what leads to our different effects. Our product names—GO, RESTORE, CHILL and DREAM—reflect the effect, rather than the strain. It’s not rocket science, but it’s pretty close.
    `,
  },
  {
    title: 'What is a Nanoemulsion?',
    description: `It’s a unique biologic process that makes CLICK sprays so effective. The biology is a bit complex, so here’s how you would explain it to your grandma: If you throw a beach ball at a chain-link fence, it bounces back. If you throw a handful of sand at that same fence, the majority of them fly through. That’s how CLICK works. 
    <br /><br/>
    The particles in our sprays pass through the soft tissues in your mouth and your bloodstream the way sand pass through a fence, which means you feel the effects in as few as 5 to 7 minutes (on average). If science is your thing, here’s a bit more on nanoemulsion that’ll heat up your hot plate. During the nanoemulsion process, the cannabinoid particles are broken down to their smallest size, then suspended in water, so each droplet contains the ideal amount of water and oil—which means absorption or bioavailability levels are optimized for every dose of CLICK. 
    <br /><br/>
    Okay, that was a lot. Next question?
    `,
  },
  {
    title: 'What is a micro dose?',
    description: `Anything under 2.5 mg is considered a micro dose. Each CLICK is filled with 80 equal 2.5 mg sprays (1 spray = 1 micro dose), which go into effect in as little as 5 to 7 minutes. The doses might be micro, but the effects can be, well, macro AF. Generally speaking, 1 CLICK will get you started, 2 CLICKS will make you feel it and 3 CLICKS, welcome to the party. Test your tolerance to find out how many micro doses do you right.
    `,
  },
  {
    title: 'How long will the effects last?',
    description: `Everyone is different. Things like diet, exercise and weight make each CLICK experience unique. For some, one click lasts a quick lunchtime run. For others, one click lasts an entire set at Coachella. We recommend starting with one click and seeing how you feel, then clicking more and more until you find the perfect number of clicks to fit your personal tolerance level.
    `,
  },
  {
    title: 'How are the effects of a water-based sublingual different from an oil-based one? ',
    description: `For starters, our water-based spray will not leave any sort of oily residue or aftertaste once sprayed. Oils can take anywhere from 1-2 hours to feel the effects while our water-based spray takes an average of 5 to 7 minutes. CLICK distributes a very fine, light, great tasting mist that is frankly, unbeatable. 
    `,
  },
  {
    title: 'How long will a bottle of CLICK last me?',
    description: `Days? Weeks? Months? That’s totally up to your lifestyle, and how often you decide to go, restore, chill or dream—alone or with friends. Each bottle has 80 2.5mg doses, so if you’re someone that’s all for sharing the love, you might want to stock up. 
    `,
  },
  {
    title: 'Is CLICK gluten free?',
    description: `If you have a gluten allergy, don’t fret. The CBD and THC used in CLICK sprays are gluten free! The only allergen to watch out for is coconut oil, which is usually safe for most people. 
    `,
  },
  {
    title: 'Does CLICK have allergens to lookout for?',
    description: `The only allergen to watch out for is coconut oil, which is usually safe for most people.
    `,
  },
  {
    title: 'Do I have to charge my CLICK device?',
    description: `Nope! CLICK is a no-accessories-required way to consume cannabis. No charging, no external battery, no lighter, no water, no paper, no battery required. 
    `,
  },
  {
    title: 'How do I lock my CLICK? ',
    description: `In the rare case you need to, turn the spray head 45° to the right (or left), until the nozzle doesn’t align with the CLICK logo or node on the front of the bottle. When you’re done clicking for the day, store it in the child resistant (CR) bag provided or a place that is inaccessible to little ones; CLICK pens are not child resistant. 
    `,
  },
];

export const faq_how_buy = [
  {
    title: `Where can I get CLICK?`,
    description: `Currently, CLICK is only available in Southern and Northern California.
     You can find a California-based dispensary or delivery service near you by clicking <a href="https://clickspray.com/">here</a>.
        `,
  },
  {
    title: 'How much does CLICK cost?',
    description: `Cali supply/demand economics are the real deal. Our recommended retail price is $30 per CLICK spray, but if they sell like hotcakes in your area don’t be surprised if they cost a bit more. And don’t forget about your local tax man—he takes his cut too!`,
  },
  {
    title: 'Can I get CLICK outside of CA?',
    description: `Not yet, but we think everyone should enjoy the benefits of CLICK so we’re working on expanding across borders. Sign up on ClickSpray.com and we’ll contact you when we land in your area.
    `,
  },
  {
    title: 'How do I get a CLICK for myself?',
    description: `For now, search our online locator for the closest CLICK retailer or delivery service near you. Keep checking ClickSpray.com as we will be updating the locator in real time. `,
  },
];

export const faq_membership = [
  {
    title: `Where can I find my proof of purchase number?`,
    description: `Every CLICK has one, and it’s redeemable online for awesome rewards through our CLICK Membership Rewards Program. Check your child resistant (CR) bag (inside the box) for the proof-of-purchase sticker—it’s the shiny, triangle on the front. Scratch it with your lucky penny to reveal your unique 9-digit code. Then sign up or log in at ClickSpray.com to redeem. 
    `,
  },
  {
    title: 'What is the CLICK Membership Rewards Program?',
    description: `The CLICK Membership Rewards Program is our way of saying thank you and giving back to those who love and support CLICK. Consumers and budtenders can sign up through our website, <a href="https://clickspray.com/">www.clickspray.com</a>, to join the rewards program, build a profile, and start earning points. There are a handful of super easy ways to earn points (things that you probably do everyday!) and your points can be redeemed for amazing, fabulous prizes. (Sign up today!)`,
  },
];
