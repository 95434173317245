import React from 'react';
import Collapsible from 'react-collapsible';

import Heading from '../shared/heading/Heading';

import styles from './Faq.module.scss';

interface IFaqSectionProps {
  data: { [key: string]: string }[];
  title: string;
}

const FaqSection = ({ data, title }: IFaqSectionProps) => (
  <div className={styles.section}>
    <div className={styles.sectionTitle}>
      <div className={styles.title}>
        <Heading color="#3308c2" shadowColor="#B740FF" underline={false}>
          {title}
        </Heading>
      </div>

      <div className={styles.titleUnderline}>
        <hr />
        <div>Questions List</div>
      </div>
    </div>
    <div className={styles.sectionBody}>
      {data.map((item, index) => (
        <Collapsible
          key={index}
          trigger={item.title}
          className={styles.collapseBlock}
          triggerClassName={styles.collapseHeader}
          triggerOpenedClassName={styles.collapseHeaderOpen}
          openedClassName={styles.collapseOpen}
          contentInnerClassName={styles.collapseContent}
        >
          <p dangerouslySetInnerHTML={{ __html: item.description }} />
        </Collapsible>
      ))}
    </div>
  </div>
);

export default FaqSection;
