import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { FluidObject } from 'gatsby-image';

import Layout from 'components/layouts/main/Layout';
import Faq from '../components/faq/Faq';

interface IFaqPageProps {
  data: {
    [imgKey: string]: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
}

const FaqPage = ({ data }: IFaqPageProps) => (
  <Layout location="/faq">
    <Helmet>
      <title>FAQ</title>
    </Helmet>
    <Faq background={data.faqBackground.childImageSharp.fluid} />
  </Layout>
);

export default FaqPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD.MM.YY")
            author
            category
            mainImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            isFeatured
          }
          fields {
            slug
          }
          excerpt(pruneLength: 150)
        }
      }
    }
    faqBackground: file(relativePath: { eq: "faq-bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
