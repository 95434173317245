import React from 'react';
import classnames from 'classnames';

import styles from './Heading.module.scss';

interface IHeadingProps {
  children?: string | number;
  className?: string;
  color?: string;
  shadowColor?: string;
  underline?: boolean;
  underlineColor?: string;
  underlineDirection?: 'start' | 'end';
  underlineOffset?: string;
  component?: string;
  [key: string]: any;
}

function Heading(props: IHeadingProps) {
  let {
    children = '',
    className = '',
    color = '#000',
    shadowColor = '#000',
    underline = true,
    underlineColor = '#000',
    underlineDirection = 'start',
    underlineOffset = '0px',
    component = 'h1',
    ...otherProps
  } = props;

  return React.createElement(
    component,
    {
      className: classnames(styles.heading, className),
      ...otherProps,
    },
    <>
      <span className={styles.headingContent} style={{ color }}>
        {children}
      </span>
      <span
        className={styles.headingContentShadow}
        style={{
          WebkitTextStrokeColor: shadowColor,
        }}
      >
        {children}
      </span>
      {underline && (
        <hr
          className={styles.headingUnderline}
          style={{
            backgroundColor: underlineColor,
            [underlineDirection === 'start' ? 'left' : 'right']: underlineOffset,
          }}
        />
      )}
    </>,
  );
}

export default Heading;
